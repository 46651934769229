import { render, staticRenderFns } from "./Popup.vue?vue&type=template&id=16b706c2&scoped=true&"
import script from "./Popup.vue?vue&type=script&lang=js&"
export * from "./Popup.vue?vue&type=script&lang=js&"
import style0 from "./Popup.vue?vue&type=style&index=0&id=16b706c2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16b706c2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/docker/volumes/gitlab-runner-builds/_data/2/ochkarik/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('16b706c2')) {
      api.createRecord('16b706c2', component.options)
    } else {
      api.reload('16b706c2', component.options)
    }
    module.hot.accept("./Popup.vue?vue&type=template&id=16b706c2&scoped=true&", function () {
      api.rerender('16b706c2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "frontend-new/components/Popup.vue"
export default component.exports