<script>
import { mapGetters } from 'vuex';
import cookies from 'browser-cookies';
import RubSymbol from 'src/components/RubSymbol';
import LdsSpinner from 'src/components/LdsSpinner';
import { setCookie } from 'src/utils/cookies';
import { Vue } from 'src/apps/vue';
import { contextAPI } from 'src/api/context';
import { productsAPI } from 'src/api/products';
import { legacyAPI } from 'src/api/legacy';
import Popup from 'src/components/Popup';
import { ANALYTICS } from 'src/constants';
import { idForAnalytics } from 'src/utils';
import ErrorNotification from 'src/components/ErrorNotification';
import { CART_STATE_GETTING, CART_STATE_UPDATING } from 'src/apps/checkout/store';
import SuccessPopup from '../SuccessPopup';

export default {
  name: 'ProductPopup',
  components: {
    LdsSpinner,
    RubSymbol,
    SuccessPopup,
    Popup,
    ErrorNotification,
  },
  props: {
    productId: {
      type: [Number, null],
      default: null,
    },
    variantId: {
      type: [Number, null],
      default: null,
    },
    backendType: {
      type: String,
      default: '',
    },
    showPopup: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      contextId: '',
      correctPricePopup: null,
      product: {},
      attributes: [],
      attributeValues: {},
      attributeString: '',
      loading: 0,
      disabledButton: false,
      showSuccess: false,
      currentVariantId: 0,
      width: 0,
      recommendedProduct: null,
      limitRecommendationProduct: 2,
      defaultQuantity: 1,
      addedProduct: [],
    };
  },
  async created() {
    document.body.style.overflow = 'hidden';
    this.loading++;
    try {
      await this.getContextId();
      this.getProduct();
      this.getProductAttributes();

      if (this.variantId) {
        this.getProductOptions();
      }
    } finally {
      this.loading--;
    }

    this.updateWidth();
    window.addEventListener('resize', this.updateWidth);
    if (!this.showPopup) {
      this.addProduct();
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateWidth);
  },
  computed: {
    ...mapGetters('checkout', {
      contextItems: 'getContextItems',
      totalSum: 'getTotalSum',
    }),
    showProduct() {
      return this.product.id && !this.loading && !this.showSuccess;
    },
    showPromotions() {
      return this.product?.promotions?.filter(item => item?.code === 'PolaroidPromo').length > 0;
    },
    attributesFiltered() {
      return this.attributes.filter(attr => attr.filter);
    },
    attributeValuesFiltered() {
      const attributesMap = {};
      this.attributesFiltered.forEach(({ id }) => {
        if (id in this.attributeValues) {
          attributesMap[id] = Number(this.attributeValues[id]);
        }
      });
      return attributesMap;
    },
    amount() {
      const id = this.currentVariantId || this.variantId || this.productId;
      const key = this.variantId || this.currentVariantId ? 'variantId' : 'productId';
      const product = this.contextItems.find(item => +id === item[key]);
      return product?.amount || 1;
    },
    currentPrice() {
      const priceSale = parseInt(this.product.priceSale.toString().replace(' ', ''), 10);

      if (Number.isNaN(priceSale) && !this.correctPricePopup) {
        return this.product.priceSale;
      }
      return this.correctPricePopup || priceSale;
    },
    simpleContext() {
      return (
        window.__X_CART_DATA__.target !== 'cart' ||
        (window.__X_CART_DATA__.target === 'cart' && !this.contextItems.length)
      );
    },
    cartState() {
      return this.simpleContext ? CART_STATE_GETTING : CART_STATE_UPDATING;
    },
    productsInCart() {
      return this.contextItems.map(item => ({
        id: item.productId || '',
        name: item.name || '',
        vendor: item.brand || '',
        price: item.price || 0,
        oldPrice: item.oldPrice || 0,
        quantity: item.amount || 1,
        variant: item.variantId,
        available: +item.enabled,
      }));
    },
  },
  methods: {
    closePopup() {
      document.body.style.overflow = 'auto';
      this.$emit('close');
    },
    updateWidth() {
      this.width = window.innerWidth;
    },
    async getContextId() {
      const context = await (async () => {
        const stateId = cookies.get('stateId');

        if (stateId) {
          try {
            return await Vue.$http.get(
              `/api/contexts/${stateId}?cs=${this.cartState}&cbp[simple]=${this.simpleContext}`,
            );
          } catch (e) {
            // не получилось ну и ладно, сгенерим новый
          }
        }

        return Vue.$http.post(`/api/contexts`);
      })();

      this.contextId = context.data.id;
      setCookie('stateId', context.data.id);
    },
    clearError(attr) {
      Vue.set(attr, 'error', false);
    },
    async addProduct() {
      if (this.attributes.length !== 0) {
        let errorCounter = 0;
        // eslint-disable-next-line no-restricted-syntax
        for (const attr of this.attributes) {
          if (this.attributeValues[attr.id]) {
            Vue.set(attr, 'error', false);
          } else {
            Vue.set(attr, 'error', true);
            errorCounter++;
          }
        }
        if (errorCounter !== 0) {
          return;
        }
      }
      try {
        globalThis.emitter.emit('showPreloader', true);
        await this.addToCart();
        await this.fetchRecommendedProduct();

        globalThis.emitter.emit('updateCart');
        if (this.backendType === 'cart') {
          this.closePopup();
        } else {
          this.showSuccess = true;
        }
      } catch (e) {
        /* eslint-disable camelcase */
        const msg = e.response?.data?.reason_phrase || e.response?.data?.message || 'Произошла ошибка';
        globalThis.emitter.emit('addNotification', msg);
      } finally {
        globalThis.emitter.emit('showPreloader', false);

        if (this.backendType === 'any-query') {
          window.dataLayer = window.dataLayer || {};
          if (window.dataLayer.cartState) {
            window.dataLayer.cartState[this.product.id] = this.amount;
          } else {
            window.dataLayer.cartState = { [this.product.id]: this.amount };
          }
        }
      }
    },
    async addToCart(product) {
      this.loading++;
      try {
        await this.getProductOptions(product);
        const payload = {
          contextId: this.contextId,
          productId: product?.id || this.productId,
          attributeValues: !product?.id ? this.attributeValues : {},
          amount: this.defaultQuantity,
        };
        await contextAPI.addItem(payload).then(res => {
          if (res.status === 200 || res.status === 201) {
            this.addedProduct.push({ productId: payload.productId });
          }
        });
        await this.$store.dispatch('checkout/bootstrap', {
          cartState: this.cartState,
          simpleContext: this.simpleContext,
        });

        let productItem = {};
        if (product) {
          productItem = {
            id: product.sku,
            name: product.name,
            price: parseInt(String(product.price).replace(/ /g, ''), 10).toFixed(2),
            oldPrice: parseInt(String(product.salePrice).replace(/ /g, ''), 10),
            brand: product.brandName,
            category: product.categoryName,
            variant: '',
            listName: 'Добавьте к вашему заказу',
            currency: 'RUB',
            idForAnalytics: idForAnalytics(product.id, []),
          };
        } else {
          const price = parseInt(String(this.product.price).replace(/ /g, ''), 10);
          productItem = {
            id: this.product.id,
            name: this.product.name,
            price: Number.isNaN(price)
              ? this.correctPricePopup || parseInt(String(this.product.price).replace(/[^0-9]/g, ''), 10)
              : price,
            oldPrice: parseInt(String(this.product.prevPrice).replace(/[^0-9]/g, ''), 10),
            brand: this.product.brand ? this.product.brand.name : '',
            category: this.product.categories[0] ? this.product.categories[0].name : '',
            variant: this.currentVariantId ? this.currentVariantId : this.product.variantId,
            idForAnalytics: idForAnalytics(
              this.product.id,
              this.currentVariantId ? [this.currentVariantId] : [this.product.variantId],
            ),
          };
        }
        this.triggerAnalyticsAddItem(productItem);
      } catch (e) {
        const msg = e.response?.data?.reason_phrase || e.response?.data?.message || 'Произошла ошибка';
        globalThis.emitter.emit('addNotification', msg);
        throw e;
      } finally {
        this.loading--;
      }
    },
    async fetchRecommendedProduct() {
      this.loading++;
      const payload = {
        productId: this.productId,
        limit: this.limitRecommendationProduct,
      };
      const { data } = await contextAPI.fetchRecommendedProduct(payload);
      this.recommendedProduct = data;
      this.triggerAnalyticsShowProduct();
      this.loading--;
    },
    getProduct() {
      this.loading++;

      const payload = {
        id: this.productId,
        vid: this.variantId,
      };

      productsAPI
        .getProduct(payload)
        .then(response => {
          this.product = response;
        })
        .catch(() => {
          this.product = {};
        })
        .finally(() => {
          this.$nextTick(() => {
            this.loading--;
          });
        });
    },
    getProductAttributes() {
      this.loading++;

      productsAPI
        .getProductAttributes({ id: this.productId })
        .then(response => {
          this.attributes = response;
        })
        .catch(() => {
          this.attributes = [];
        })
        .finally(() => {
          this.loading--;
        });
    },
    getCorrectPricePopup() {
      this.loading++;
      globalThis.emitter.emit('showPreloader', true);

      const payload = {
        id: this.productId,
        amount: this.amount,
        attributeString: this.attributeString,
      };

      legacyAPI
        .getCorrectProductPrice(payload)
        .then(response => {
          if (response.success) {
            this.correctPricePopup = response.price;
            this.currentVariantId = response.variantId ?? 0;

            if (!response.variantId) {
              globalThis.emitter.emit('setErrorNotify', 'Товара с такими параметрами нет в наличии');
              this.disabledButton = true;
            } else {
              this.disabledButton = false;
            }
          }
        })
        .finally(() => {
          globalThis.emitter.emit('showPreloader', false);
          this.loading--;
        });
    },
    changeAttribute() {
      let attributeValues = ``;
      Object.keys(this.attributeValuesFiltered).forEach(item => {
        attributeValues += `&attribute_values[${item}]=${this.attributeValues[item]}`;
      });
      this.attributeString = attributeValues;
    },
    getProductOptions(product) {
      this.loading++;

      const payload = {
        id: product?.id || this.productId,
        vid: !product?.id ? this.currentVariantId || this.variantId : null,
      };

      return productsAPI
        .getProductOptions(payload)
        .then(response => {
          response.forEach(attr => {
            if (attr.selected && !product?.id) {
              this.attributeValues[attr.id] = attr.selected;
            }
          });
        })
        .finally(() => {
          this.loading--;
        });
    },
    triggerAnalyticsShowProduct() {
      const admitad = { categoryId: 'Добавьте к вашему заказу' };
      const gtag = this.recommendedProduct.map((item, i) => ({
        name: item.name,
        id: item.sku,
        price: parseInt(String(item.price).replace(/ /g, ''), 10).toFixed(2),
        currency: 'RUB',
        brand: item.brandName,
        category: item.categoryName,
        index: i + 1,
        quantity: 1,
        idForAnalytics: idForAnalytics(item.id, []),
      }));
      const payload = { admitad, gtag };

      window.xcore.trigger('showProduct', payload);
    },

    triggerAnalyticsAddItem(productItem) {
      const product = {
        ...productItem,
        quantity: this.defaultQuantity,
        totalSum: this.totalSum,
        cart: this.productsInCart,
      };
      window.xcore.trigger(ANALYTICS.ITEM_ADD, { product });
    },
  },

  watch: {
    attributeString() {
      if (Object.keys(this.attributeValuesFiltered).length === this.attributesFiltered.length) {
        this.getCorrectPricePopup();
      }
    },
  },
};
</script>

<template>
  <Popup class="c-base product-add-to-cart" @close="closePopup">
    <div v-if="loading" class="v-product-popup__spinner">
      <LdsSpinner />
    </div>
    <div v-if="showProduct" class="popup-body">
      <div class="v-product-popup__header">
        <p class="v-product-popup--title">{{ product.name }}</p>
        <span class="v-product-popup--price"> {{ currentPrice.toLocaleString() }} <RubSymbol>И</RubSymbol> </span>
      </div>
      <div class="v-product-popup__body">
        <div class="v-product-popup--image">
          <img :src="product.imageThumbnail" alt="product" />
        </div>
        <div class="v-product-popup--attributes">
          <div v-for="attr in attributesFiltered" :key="attr.id" class="v-product-popup--attribute">
            <div class="v-product-popup--attribute-name">{{ attr.name }}</div>
            <select
              class="v-product-popup--attribute-input"
              @focus="clearError(attr)"
              @change="changeAttribute"
              :class="{ error: attr.error }"
              v-model="attributeValues[attr.id]"
            >
              <option v-for="item in attr.values" :key="item.id" :value="item.id">{{ item.value }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="v-product-popup__footer">
        <button @click.prevent="addProduct" :disabled="disabledButton">Добавить в корзину</button>
      </div>
    </div>
    <div v-if="!product.id && !loading" class="popup-body">Товар не найден</div>
    <SuccessPopup
      v-if="showSuccess"
      :amount="+amount"
      :price="currentPrice"
      :product="product"
      :attributeValues="attributeValues"
      :attributes="attributesFiltered"
      :recommendedProduct="recommendedProduct"
      :contextItems="contextItems"
      :contextId="contextId"
      :addedProduct="addedProduct"
      :parentLoading="loading"
      :variantId="currentVariantId"
      @addProduct="addToCart"
      @close="closePopup"
    />
    <ErrorNotification />
  </Popup>
</template>

<style lang="scss" src="./style.scss"></style>
